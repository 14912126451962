import React from 'react'
import "./src/assets/css/main.css"
import "./src/assets/css/fonts.css"
import 'animate.css'
import 'react-toastify/dist/ReactToastify.css';
import 'react-slideshow-image/dist/styles.css'

export const onClientEntry = () => {
    if (process.env.NODE_ENV !== 'production') {
        const whyDidYouRender = require('@welldone-software/why-did-you-render')
        whyDidYouRender(React, {
            trackAllPureComponents: true
        })
    }
}
onClientEntry()

